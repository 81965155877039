.container {
    position: relative;
    min-height: 100vh;
}

.background {
    background-image: url("/public/imagenes/homeback.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    padding: 5%;
    z-index: 1;
}

.select {
    padding: 2px;
}

.logoContainer img {
    width: 100%;
    max-height: 100%;
}

.logo {
    max-width: 100%;
}

.titleContainer {
    width: 100%;
}


.welcomeContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin: 0;
}

.paragraph {
    font-size: 1.4em;
    max-width: 90%;
    font-weight: bold;

}

.optionContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    background-color: white;
    padding: 2%;
    border-radius: 5px;
    height: 80px;
}


.selectContainer,
.buttonContainer {
    flex: 1;
    margin: 1%;
    padding: 1%;
    border-radius: 5px;
}

.select {
    width: 100%;
    height: 50%;
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: black;
}

.button {
    width: 100%;
    height: 50%;
    background-color: red;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2% auto;
}


.flag {
    width: 40px;
    height: 25px;
    margin: 0 6px;
}

.flag:hover {
    transform: scale(1.2);
    transition: transform 0.1s ease-in-out;
    cursor: pointer;
}

.avatar-container {
    position: relative;
    display: inline-block;
}

.tooltip {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 4px;
    border-radius: 5px;
    border: 1px solid #fff;
    top: -35px;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    white-space: nowrap;
}

.avatar-container:hover .tooltip {
    display: block;
}

@media (max-width:1400px) {
    .container {
        position: relative;
        min-height: 100vh;
    }

    .background {
        background-image: url("/public/imagenes/homeback.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: white;
        padding: 20px;
        z-index: 1;
    }

    .select {
        padding: 2px;
    }

    .logoContainer {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    .logoContainer img {
        width: 100%;
        height: 100%;
    }

    .logo {
        max-width: 100%;
    }

    .titleContainer {
        width: 100%;
    }

    .title {
        font-size: 10em;
        margin: 0;
        padding: 0 20px;
        margin-top: -0.2em;
    }

    .welcomeContainer {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        margin: 0;
    }

    .welcome {
        font-size: 6em;
        color: red;
        margin-right: 3em;
        margin-top: -1.4em;
    }

    .paragraph {
        font-size: 16px;
        max-width: 90%;
        margin: 20px auto;
        font-weight: bold;
        margin-top: -0.2em;
    }

    .optionContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px auto;
        width: 40%;
        background-color: white;
        padding: 10px;
        border-radius: 5px;
    }

    .selectContainer {
        flex: 1;
        margin-right: 5px;
        padding: 5px;
        border-radius: 5px;
    }

    .buttonContainer {
        flex: 1;
        margin-left: 5px;
        padding: 5px;
        border-radius: 5px;
    }

    .select {
        width: 100%;
        height: 50%;
        background-color: transparent;
        border: 1px solid #ccc;
        border-radius: 5px;
        color: black;
    }

    .button {
        width: 100%;
        height: 50%;
        background-color: red;
        color: white;
        border: none;
        cursor: pointer;
        border-radius: 5px;
    }

    .imageContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px auto;
    }

    .flag {
        width: 30px;
        height: 20px;
        margin: 0 4px;
    }

    .flag:hover {
        transform: scale(1.2);
        transition: transform 0.1s ease-in-out;
        cursor: pointer;
    }
}


@media (max-width: 768px) {

    .optionContainer {
        width: 100%;
    }

    .logoContainer img {
        width: 80%;
        height: 80%;
    }

    .paragraph {
        font-size: 13px;
        max-width: 100%;
        font-weight: bold;
        margin-top: 1em;
    }

    .select option {
        font-size: 9px;
    }
}



@media screen and (min-width: 1920px) {
    .paragraph {
        font-size: 1.8em;
        max-width: 90%;
        font-weight: bold;
        margin-bottom: 1em;
    }

    .flag {
        width: 3.5em;
        height: 2em;
        margin: 0 6px;
    }

    .flag:hover {
        transform: scale(1.2);
        transition: transform 0.1s ease-in-out;
        cursor: pointer;
    }
}

@media screen and (min-width: 2304px) {
    .container {
        position: relative;
        min-height: 100vh;
    }

    .background {
        background-image: url("/public/imagenes/homeback.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
    }

    .select {
        padding: 2px;
    }

    .logoContainer img {
        width: 600px;
        max-height: 100%;
    }

    .paragraph {
        font-size: 4em;
        max-width: 90%;
        font-weight: bold;
        margin-top: 1em;
    }

    .optionContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        background-color: white;
        border-radius: 5px;
        height: 175px;
        margin-top: 10%;
    }


    .selectContainer,
    .buttonContainer {
        flex: 1;
        margin: 1%;
        padding: 1%;
        border-radius: 5px;
        height: 5em;
    }

    .select {
        width: 100%;
        height: 70%;
        background-color: transparent;
        border: 1px solid #ccc;
        border-radius: 5px;
        color: black;
        font-size: 18px;
    }

    .button {
        width: 100%;
        height: 80%;
        background-color: red;
        color: white;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        font-size: 17px;
    }

    .imageContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2% auto;
    }


    .flag {
        width: 9em;
        height: 5em;
        margin: 0 10px;
    }

    .flag:hover {
        transform: scale(1.2);
        transition: transform 0.1s ease-in-out;
        cursor: pointer;
    }

    .avatar-container {
        position: relative;
        display: inline-block;
    }

    .tooltip {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.7);
        color: #fff;
        padding: 4px;
        border-radius: 5px;
        border: 1px solid #fff;
        top: -35px;
        left: 50%;
        transform: translateX(-50%);
        display: none;
        white-space: nowrap;
    }

    .avatar-container:hover .tooltip {
        display: block;
    }
}